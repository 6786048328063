/*------------------------------------------------------------------
Project:    Zemo Personal Portfolio
Version:    1.0
Primary use:    pxdraft

[Table of contents]

1. Body
2. Header
3. Footer
4. Blog
5. Section
6. Home Banner
7. About
8. Services
9. Resume
10. Portfolio
11. Testimonial
12. Contact Us

-------------------------------------------------------------------*/
@import "../../plugin/bootstrap/css/bootstrap.min.css";
@import "../../plugin/bootstrap/icons/bootstrap-icons.css";
@import "../../plugin/owl-carousel/css/owl.carousel.min.css";
@import "../../plugin/magnific/magnific-popup.css";


@import "scss/variable";
@import "scss/mixin";
@import "scss/header";
@import "scss/footer";
@import "scss/blog";
@import "scss/base";
@import "scss/button";
@import "scss/color";
@import "scss/style";
@import "scss/title";
