@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: inherit;
  }
}

html {
	overflow-x: hidden;
}

body {
	color: $px-body;
	font-family: $px-font;
	line-height: $px-line-height;
	font-size: $px-font-size;
	font-weight: 400;
	overflow-x: hidden;
}

.font-alt {
	font-family: $px-font-alt;
}

img {
	max-width: 100%;
}

* {
	outline: none !important;
}

a {
	color: $px-theme;
	text-decoration: none;
	@include px-hover {
		color: darken($px-theme, 8%);
		text-decoration: none;
	}
}

mark {
    background-image: linear-gradient($px-theme, $px-theme);
    background-size: 100% 3px;
    background-repeat: no-repeat;
    background-position: 0 bottom;
    background-color: transparent;
    padding: 0;
    color: currentColor;
}

/* ----------------------
*	Loading
---------------------------*/
#loading {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: $px-theme;
	z-index: 99999;
}
.load-circle {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 50px;
	 span {
	  display: inline-block;
	  width: 64px;
	  height: 64px;
	  &:after {
		  content: " ";
		  display: block;
		  width: 46px;
		  height: 46px;
		  margin: 1px;
		  border-radius: 50%;
		  border: 5px solid $px-white;
		  border-color: $px-white transparent $px-white transparent;
		  animation: lds-dual-ring 1.2s linear infinite;	
	  }
	}
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



/* ----------------------
*	owl 
---------------------------*/
.owl-dots {
	text-align: center;
	.owl-dot {
		display: inline-block;
		vertical-align: top;
		width: 10px;
		height: 10px;
		background: transparent;
		border: 1px solid $px-white;
		@include transition(ease all 0.55s);
		border-radius: 50%;
		margin: 0 5px;	
		&.active {
			background: $px-white;
		}
	}
}

.owl-carousel {
	.owl-item {
		img {
			width: auto;
			max-width: 100%;
		}
	}
}


/* Page title
--------------------------------*/
.page-title {
	padding: 160px 0 80px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	.container {
		position: relative;
		z-index: 1;
	}
	@include down-lg {
		padding-top: 130px;
		padding-bottom: 50px;
	}
	h1 {
		font-weight: 700;
		margin-bottom: 15px;
		color: $px-white;
		@include down-md {
			font-size: 30px;
		}
	}
}


/* Breadcrumbs
--------------------------------*/
.breadcrumb {
	margin: 0;
	padding: 0;
	list-style: none;
	background: none;
	border-radius: 0;
	li {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		font-weight: 400;
		position: relative;
		color: $px-white;
		+ li {
			padding-left: 17px;
			margin-left: 17px;
			&:after {
				content:"";
				position: absolute;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: currentColor;
				opacity: 0.5;
				left: -2px;
				top: 0;
				bottom: 0;
				margin: auto;
			}
		}
		a {
			color: $px-white;
		}
	}
}